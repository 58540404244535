import { useSelector } from "react-redux";
import store from "../redux/redux_index";
import {
  setIsLoadingAttributeRedux,
  setListDataAttributeRedux,
} from "../redux/attributes_redux/AttributesRedux";

export const useAttributesList = () =>
  useSelector((state) => state.attributes.listData);

export const useAttributeFromId = (id) => {
  const list = useSelector((state) => state.attributes.listData);

  return list.find((e) => e.id == id);
};

export const useAttributesIsLoading = () =>
  useSelector((state) => state.attributes.isLoading);

export const setAttributesLoading = ({ isLoading }) => {
  store.dispatch(setIsLoadingAttributeRedux(isLoading));
};

export const setAttributesList = ({ data }) => {
  store.dispatch(setListDataAttributeRedux(data));
};
