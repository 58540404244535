import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { setCanvasOpen } from "../utils/extensions/CanvasExtension";

const AppOffCanvas = ({ child, title, isOpen, close }) => {
  return (
    <>
      <div
        className={`z-10 fixed top-0 left-0 w-full h-full bg-blackTransparant  ${
          isOpen ? "h-0 w-0 translate-x-0" : "-translate-x-full"
        }`}
      >
        <div
          className={`fixed top-0 left-0 h-full w-max bg-white shadow-lg transform transition-transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex flex-row items-center">
            <div className="flex-1 text-lg p-3"> {title}</div>
            <div
              className="p-3 hover:cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setCanvasOpen({ isOpen: false });
                if (close != null) {
                  close();
                }
              }}
            >
              <FontAwesomeIcon icon={faClose} style={{ fontSize: "1.1em" }} />
            </div>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {child}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppOffCanvas;
