import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ProductScreenCategoriesPath } from "../screens/products_screen/ProductsCategoriesScreen";
import { categories } from "../utils/dummy_data";
import { setCanvasOpen } from "../utils/extensions/CanvasExtension";
import NavigateExtension from "../utils/extensions/NavigateExtension";
import { useCategoriesList } from "../utils/extensions/CategoriExtension";

export default function AppMenuCanvas() {
  const list = useCategoriesList();
  return (
    <div className="flex flex-col w-max">
      <CustomTile name={"ÜRÜNLER"} dataList={list}></CustomTile>
      <CustomTile name={"KURUMSAL SATIŞ"}></CustomTile>
      <CustomTile name={"BLOG"}></CustomTile>
      <CustomTile name={"AKADEMİ"}></CustomTile>
      <CustomTile name={"İLETİŞİM"}></CustomTile>
      <CustomTile name={"HAKKIMIZDA"}></CustomTile>
    </div>
  );
}

function CustomTile({ name, dataList }) {
  const [isActive, setIsActive] = useState(false);
  const { goToPath } = NavigateExtension();

  return (
    <div
      onClick={() => {
        // setIsActive(!isActive);
        setCanvasOpen({ isOpen: false });
        goToPath(ProductScreenCategoriesPath);
      }}
      on
      className="flex flex-col hover:cursor-pointer hover:bg-gray-50  hover:text-gray-600"
    >
      {/*  collapsenin title */}
      <div className="flex flex-row p-2 text-sm ">
        <div className="w-full">{name}</div>
        <div className="pl-3 pr-2">
          <FontAwesomeIcon
            icon={isActive ? faChevronDown : faChevronRight}
          ></FontAwesomeIcon>
        </div>
      </div>
      {/*  collapsenin expanded alanı */}
      {isActive ? (
        <CustomExpandedTile list={dataList}></CustomExpandedTile>
      ) : null}
    </div>
  );
}

function CustomExpandedTile({ list }) {
  return (
    <div className="flex flex-col w-full justify-center content-center">
      {list?.map((e) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="hover:bg-gray-50 w-full hover:cursor-pointer bg-white text-black hover:text-gray-600 pl-7 pt-1 flex flex-row  items-center   content-center"
          >
            <h6 className="text-sm">{e.name}</h6>
            <div className="pl-2 pr-2">
              <FontAwesomeIcon
                style={{ fontSize: "0.75em" }}
                icon={faChevronRight}
              ></FontAwesomeIcon>
            </div>
          </div>
        );
      })}
    </div>
  );
}
