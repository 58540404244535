import React from "react";
import AppCarousel from "../../../components/AppCarousel";

export default function AppSliders() {
  const sliderList = [
    "https://gaziantepdoguscom.teimg.com/crop/1280x720/gaziantepdogus-com/sites/2023/12/04/kuzey-isiklari-nedir-kuzey-isiklari-nasil-olusur.webp",
    "https://images.unsplash.com/photo-1598164422557-c192ac533e4a?q=80&w=2804&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1612853228637-e89987ca1df3?q=80&w=2959&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1618634494100-d983a1020b36?q=80&w=3015&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];
  return (
    <div className="pt-5">
      <AppCarousel sliders={sliderList}></AppCarousel>
    </div>
  );
}
