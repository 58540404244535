import React from "react";

export default function AppCheckBox({ value, isSelected, onChanged }) {
  return (
    <div
      className="hover:cursor-pointer hover:text-stone-500 flex flex-row h-max w-full pl-3 pr-2 content-center"
      onClick={() => {
        if (onChanged == null) return;
        onChanged(!isSelected);
      }}
    >
      <input
        className="accent-black"
        checked={isSelected}
        type="checkbox"
      ></input>

      <div className="pl-1">{value}</div>
    </div>
  );
}
