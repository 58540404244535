import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) ?? false,
  },
  reducers: {
    login: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      console.log("user-payload-> " + JSON.stringify(action.payload));
      state.user = action.payload.data;
    },
    logout: (state) => {
      state.user = false;
      localStorage.removeItem("user");
    },
  },
});

export const { login, logout } = auth.actions;

export default auth.reducer;
