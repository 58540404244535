import React, { useEffect, useState } from "react";
import { mostSellersList } from "../../../utils/dummy_data";
import NavigateExtension from "../../../utils/extensions/NavigateExtension";
import { ProductScreenCategoriesPath } from "../../products_screen/ProductsCategoriesScreen";
import NetworkService from "../../../manager/network_manager";
import { GET_PRODUCT } from "../../../constant/end_point";
import { useCategoriFromId } from "../../../utils/extensions/CategoriExtension";

function MostSellers() {
  const [products, setProducts] = useState([]);

  const { sendPost } = NetworkService();

  async function fetchProducts() {
    const response = await sendPost({
      endPoint: GET_PRODUCT,
      postData: {},
    });

    if (response.success != true) {
      return {
        success: false,
        message: response.message,
      };
    }

    setProducts(response.data);
  }
  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const scrollContainer = document.getElementById("scrollContainer");
    let isDown = false;
    let startX;
    let scrollLeft;

    scrollContainer.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - scrollContainer.offsetLeft;
      scrollLeft = scrollContainer.scrollLeft;
    });

    scrollContainer.addEventListener("mouseleave", () => {
      isDown = false;
    });

    scrollContainer.addEventListener("mouseup", () => {
      isDown = false;
    });

    scrollContainer.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainer.offsetLeft;
      const walk = x - startX; // Hızı ayarlamak için çarpanı değiştirebilirsiniz
      scrollContainer.scrollLeft = scrollLeft - walk;
    });
  });

  return (
    <div className="flex flex-col">
      <h1 className="p-5 pl-4 md:pl-8 text-lg md:text-2xl">EN ÇOK SATANLAR</h1>
      <div
        className="flex flex-row w-[100%] overflow-x-auto "
        id="scrollContainer"
      >
        {products.map((e) => {
          return <MostSellerListTile item={e}></MostSellerListTile>;
        })}
      </div>
    </div>
  );
}

export default MostSellers;

export function MostSellerListTile({ item }) {
  const { name, price, imageUrl, categories, path, breadcrumbName } = item;

  const categori = useCategoriFromId(
    (categories != null && categories?.length != 0) == true ? categories[0] : ""
  );

  const { goToPath } = NavigateExtension();
  return (
    <div className="p-5  min-w-[300px] ">
      <div
        onClick={(e) => {
          e.stopPropagation();

          goToPath(
            `${ProductScreenCategoriesPath}${categori?.breadcrumbName}${breadcrumbName}`
          );
        }}
        className="hover:shadow-xl hover:cursor-pointer flex flex-col justify-center items-center content-center self-center text-center p-5 h-full shadow-md rounded-lg"
      >
        <div className=" w-32 h-full  object-cover">
          <img alt="" src={imageUrl}></img>
        </div>
        <div className="justify-center self-center p-2 flex-1 text-xs md:text-sm text">
          {name}
        </div>
        <div className="text-xs md:text-lg font-bold">{`${price?.amount} ${price?.currenySembol}`}</div>
      </div>
    </div>
  );
}
