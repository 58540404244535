import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HomeScreenPath } from "../screens/home_screen/HomeScreen";
import { useBreadCrumbsList } from "../utils/extensions/BreadCrumbsExtension";
import NavigateExtension from "../utils/extensions/NavigateExtension";

export default function AppBreadCrumbs() {
  const list = useBreadCrumbsList();
  function capitalizeWords(str) {
    return str
      .split(" ") // Boşluklara göre böler
      .map(
        (word) =>
          word.charAt(0).toLocaleUpperCase("tr-TR") +
          word.slice(1).toLocaleLowerCase("tr-TR")
      ) // Her kelimenin ilk harfini büyük, diğerlerini küçük yapar
      .join(" "); // Tek bir stringe birleştirir
  }

  const breadCrumbModel = (id) => {
    const model = list.findLast(
      (e) => e.id.replace("/", "") === id.replace("/", "")
    );

    return model;
  };
  const { goToPath } = NavigateExtension();

  const { pathname } = useLocation();

  const getBrandList = () => {
    var list = pathname.split("/");

    list = list.filter((e) => e !== "");

    list = ["Anasayfa", ...list];

    return list;
  };

  const isLast = (index) => {
    return getBrandList().length - 1 === index;
  };

  const goToSelected = (index) => {
    var list = getBrandList();
    list = list.filter((item, i) => i !== 0);

    list = list.slice(0, index);

    const path = list.join("/");
    goToPath(`/${path}`);
  };

  useEffect(() => {
    console.log(pathname);
  }, [pathname]);

  return (
    <div className={`pl-5 md:pl-10 flex flex-row`}>
      {getBrandList().map((e, i) => {
        const model = breadCrumbModel(e);
        return (
          <div key={e} className="flex flex-row  items-center">
            {i !== 0 ? <div>{">"}</div> : null}
            <div
              onClick={() => {
                if (isLast(i)) {
                  return;
                }
                if (i === 0) {
                  goToPath(HomeScreenPath);
                  return;
                }
                goToSelected(i);
              }}
              className={`text-xs md:text-sm lg:text-base p-2 ${
                isLast(i) ? "" : " font-semibold hover:cursor-pointer"
              }`}
            >
              {capitalizeWords(model?.name || e)}
            </div>
          </div>
        );
      })}
    </div>
  );
}
