import {
  faBars,
  faBasketShopping,
  faChevronDown,
  faChevronRight,
  faMagnifyingGlass,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { HomeScreenPath } from "../../../screens/home_screen/HomeScreen";
import { ProductScreenCategoriesPath } from "../../../screens/products_screen/ProductsCategoriesScreen";
import { categories } from "../../../utils/dummy_data";
import {
  setCanvasOpen,
  useCanvasIsOpen,
} from "../../../utils/extensions/CanvasExtension";
import NavigateExtension from "../../../utils/extensions/NavigateExtension";
import AppMenuCanvas from "../../AppMenuCanvas";
import AppOffCanvas from "../../AppOffCanvas";
import { useCategoriesList } from "../../../utils/extensions/CategoriExtension";

export default function AppLayoutHeader() {
  return (
    <>
      <div className="flex flex-col">
        <AppLayoutHeaderTop></AppLayoutHeaderTop>
        <AppLayoutHeaderBottom></AppLayoutHeaderBottom>
      </div>
    </>
  );
}

function AppLayoutHeaderBottomIcontile({
  name,
  showArrow = true,
  dataList,
  onClick,
}) {
  const [isHover, setIsHover] = useState(false);
  const [isPopOpActive, setIsPopUpActive] = useState(false);
  const { goToPath } = NavigateExtension();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!isHover) {
        setIsPopUpActive(false);
      }
    }, 100);
    return () => {
      clearTimeout(timeOut);
    };
  }, [isHover]);

  return (
    <div
      onMouseEnter={() => {
        setIsHover(true);
        setIsPopUpActive(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      className="w-full flex flex-col"
    >
      <div className="hover:text-gray-600 hover:cursor-pointer">
        <div
          onClick={onClick}
          className="flex flex-row justify-center items-center "
        >
          <div className=" text-xs lg:text-base">{name}</div>
          {showArrow ? (
            <div className="pl-2 w-12">
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ fontSize: "1em" }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {isPopOpActive ? (
        <div
          onMouseEnter={() => {
            setIsHover(true);
            setIsPopUpActive(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          className="z-10 absolute flex-col w-full animate-fade-in justify-centerself-center bg-transparent mt-8 ml-1"
        >
          {dataList?.map((e) => (
            <AppLayoutHeaderBottomIconExpandedTile
              item={e}
            ></AppLayoutHeaderBottomIconExpandedTile>
          ))}
        </div>
      ) : null}
    </div>
  );
}

function AppLayoutHeaderBottomIconExpandedTile({ item, isSub }) {
  const [isHover, setIsHover] = useState(false);
  const [isPopOpActive, setIsPopUpActive] = useState(false);
  const { goToPath } = NavigateExtension();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!isHover) {
        setIsPopUpActive(false);
      }
    }, 100);
    return () => {
      clearTimeout(timeOut);
    };
  }, [isHover]);
  const list = useCategoriesList();

  const getSubKategories = () => {
    return list.filter((e) => e.topCategoriId == item.id);
  };
  return (
    <>
      <div className="flex flex-col ">
        <div
          onClick={() => {
            if ((getSubKategories().length != 0) == true) {
              setIsPopUpActive(!isPopOpActive);
              return;
            }

            goToPath(`${ProductScreenCategoriesPath}${item.breadcrumbName}`);
            setIsPopUpActive(false);
          }}
          className={`${
            isSub ? "pl-6 text-xs" : "pl-4"
          } hover:cursor-pointer w-96 hover:bg-gray-50 pr-4 pt-1 pb-1 h-max shadow-md bg-white flex  flex-row items-center content-center `}
        >
          <div className="flex flex-row w-full justify-center  content-center">
            <div className={`${isSub ? "text-xs" : "text-sm"} p-1 flex-1`}>
              <h6>{item.name}</h6>
            </div>
            {(getSubKategories().length != 0) == true ? (
              <div className="pr-1 ">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ fontSize: "0.75em" }}
                />
              </div>
            ) : null}{" "}
          </div>
        </div>

        {isPopOpActive === true ? (
          <div className="">
            {getSubKategories().map((e) => (
              <AppLayoutHeaderBottomIconExpandedTile
                item={e}
                isSub={true}
              ></AppLayoutHeaderBottomIconExpandedTile>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}

function AppLayoutHeaderBottom() {
  const list = useCategoriesList();

  const getListTopKategories = () => {
    return list.filter((e) => e.topCategoriId == null);
  };
  const { goToPath } = NavigateExtension();
  return (
    <>
      <div className="visible  md:invisible md:h-0 w-1/3 flex items-center justify-center content-center self-center">
        <AppLayoutHeaderLogo></AppLayoutHeaderLogo>
      </div>

      <div className="md:visible  h-0 invisible md:text-base md:h-max">
        <div className="flex flex-row items-center w-12/12 self-center justify-center overflow-hidden">
          <AppLayoutHeaderBottomIcontile
            onClick={() => {
              goToPath(ProductScreenCategoriesPath);
            }}
            name={"ÜRÜNLER"}
            dataList={getListTopKategories()}
          />
          <AppLayoutHeaderBottomIcontile name={"KURUMSAL SATIŞ"} />
          <AppLayoutHeaderBottomIcontile name={"HAKKIMIZDA"} />
          <AppLayoutHeaderLogo></AppLayoutHeaderLogo>
          <AppLayoutHeaderBottomIcontile name={"BLOG"} showArrow={false} />
          <AppLayoutHeaderBottomIcontile name={"AKADEMİ"} />
          <AppLayoutHeaderBottomIcontile name={"İLETİŞİM"} showArrow={false} />
        </div>
      </div>
    </>
  );
}

function AppLayoutHeaderLogo({ className = "" }) {
  const { goToPath } = NavigateExtension();

  return (
    <div
      onClick={() => {
        goToPath(HomeScreenPath);
      }}
      className={
        " hover:cursor-pointer h-32 w-full text-white justify-center items-center bg-black flex"
      }
    >
      <div className="flex flex-col text-center items-center content-center justify-center h-full w-60">
        HomeMade
      </div>
    </div>
  );
}

function AppLayoutHeaderTop() {
  return (
    <>
      {
        //telefondan girildiğinde açılıacak
      }
      <AppOffCanvas
        isOpen={useCanvasIsOpen()}
        title={"MENÜ"}
        child={<AppMenuCanvas></AppMenuCanvas>}
      ></AppOffCanvas>

      <div className="flex flex-row  w-12/12">
        <div
          onClick={() => {
            setCanvasOpen({ isOpen: true });
          }}
          className="md:invisible visible flex content-center w-full p-6"
        >
          <CustomIcon
            extraWidget={<div className="pl-2">MENÜ</div>}
            icon={faBars}
          ></CustomIcon>
        </div>
        <div className="flex flex-row-reverse w-full p-6">
          <BasketIcon></BasketIcon>
          <ProfileIcon></ProfileIcon>
          <CustomIcon icon={faMagnifyingGlass} className={"pr-5"}></CustomIcon>
        </div>
      </div>
    </>
  );
}

function BasketIcon() {
  return (
    <CustomIcon
      icon={faBasketShopping}
      className="pr-1"
      extraWidget={<div>(0)</div>}
    ></CustomIcon>
  );
}
function ProfileIcon() {
  return <CustomIcon icon={faUser} className={"pr-5"}></CustomIcon>;
}

function CustomIcon({ icon, className, extraWidget }) {
  return (
    <div className="flex flex-row items-center cursor-pointer text-black  hover:text-gray-600 rounded-full">
      <FontAwesomeIcon
        icon={icon}
        style={{ fontSize: "1em" }}
        className={className}
      />
      {extraWidget}
    </div>
  );
}
