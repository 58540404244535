import React from "react";
import AppBreadCrumbs from "../../components/AppBreadCrumbs";
import AppLayoutHeader from "../../components/app_layout/header/AppLayoutHeader";

import NavigateExtension from "../../utils/extensions/NavigateExtension";
import { useCategoriesList } from "../../utils/extensions/CategoriExtension";

function ProductsCategoriesScreen() {
  const list = useCategoriesList();
  return (
    <div className="flex flex-col">
      <AppLayoutHeader></AppLayoutHeader>

      <AppBreadCrumbs></AppBreadCrumbs>
      <div className=" flex self-center pt-12 text-lg md:text-2xl">ÜRÜNLER</div>
      <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-screen h-max justify-items-center">
        {list.map((e) => {
          return (
            <ProductsListTile key={e.imageUrl} item={e}></ProductsListTile>
          );
        })}
      </div>
    </div>
  );
}

export function ProductsListTile({ item }) {
  const { goToPath } = NavigateExtension();

  return (
    <div
      onClick={() => {
        goToPath(`${ProductScreenCategoriesPath}${item.breadcrumbName}`);
      }}
      className="flex p-5 flex-col h-max w-max justify-center self-center content-center bg-red text-center"
    >
      <div className="h-80 w-80 object-contain">
        <img alt="" src={item.imageUrl} className="rounded-lg"></img>
      </div>
      <div className="pt-1">{item.name} </div>
    </div>
  );
}

export default ProductsCategoriesScreen;

export const ProductScreenCategoriesPath = "/kategori";
