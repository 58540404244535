import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AppBreadCrumbs from "../../components/AppBreadCrumbs";
import AppLayoutHeader from "../../components/app_layout/header/AppLayoutHeader";
import { mostSellersList, nameFromBrdName } from "../../utils/dummy_data";
import { setBreadCrumbsList } from "../../utils/extensions/BreadCrumbsExtension";
import { MostSellerListTile } from "../home_screen/widgets/MostSellers";
import { useCategoriesList } from "../../utils/extensions/CategoriExtension";
import { GET_PRODUCT } from "../../constant/end_point";
import NetworkService from "../../manager/network_manager";
import AppCheckBox from "../../components/AppCheckBoxWidget/AppCheckBox";
import AppExpandedWidget from "../../components/AppExpandedWidget/AppExpandedWidget";
import ProductScrenHook from "./ProductScreenHook";
import AppOffCanvas from "../../components/AppOffCanvas";
import { setCanvasOpen } from "../../utils/extensions/CanvasExtension";

export default function ProductsScreen() {
  const { id } = useParams();
  const list = useCategoriesList();
  const categori = nameFromBrdName(id, list);

  const hook = ProductScrenHook();
  useEffect(() => {
    setBreadCrumbsList({
      value: {
        id: id,
        name: categori?.name,
      },
    });

    if (categori === "") return;
    hook.fetchProducts({ id: categori.id });
  }, [categori]);

  return (
    <div className="flex flex-col">
      <AppLayoutHeader></AppLayoutHeader>
      <AppBreadCrumbs></AppBreadCrumbs>
      <div className=" pt-3  pb-3 flex self-center">{categori?.name}</div>
      <ProductionsLayer hook={hook}></ProductionsLayer>
    </div>
  );
}

function ProductionsLayer({ hook }) {
  const [canvasOpen, setCanvasOpen] = useState(false);
  return (
    <div className="flex flex-col">
      <div
        onClick={() => {
          setCanvasOpen(true);
        }}
        className=" hover:cursor-pointer md:invisible flex content-center justify-end"
      >
        <div className="shadow-sm hover:shadow-lg rounded-lg pl-4 pr-4 pt-2 pb-2 m-3">
          filtrele
        </div>
      </div>

      <div className="flex flex-row justify-start content-start ">
        <AppOffCanvas
          isOpen={canvasOpen}
          close={() => {
            setCanvasOpen(false);
          }}
          title={"Filtrele"}
          child={
            <div className=" min-w-40  w-[40vw] flex flex-col">
              {hook.topAttrbiutes.map((e) => (
                <AppExpandedWidget
                  titleChild={<LeftListTile name={e?.name}></LeftListTile>}
                  expandedChilds={hook.getAttrbiuteFromId(e?.id).map((e2) => {
                    return (
                      <div className="pl-2 pb-2">
                        <AppCheckBox
                          value={e2?.name}
                          isSelected={hook.isSelected(e2.id)}
                          onChanged={(v) => {
                            hook.handleSelectedList(e2.id);
                          }}
                        ></AppCheckBox>
                      </div>
                    );
                  })}
                ></AppExpandedWidget>
              ))}
            </div>
          }
        ></AppOffCanvas>
        <LeftFilterPage hook={hook}></LeftFilterPage>
        <RightProductsPage hook={hook}></RightProductsPage>
      </div>
    </div>
  );
}

function RightProductsPage({ hook }) {
  return (
    <div className="w-[100%] md:w-[80%] text-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  self-center content-center justify-center">
      {hook.getFiltredProducts?.map((e) => {
        return <MostSellerListTile item={e}></MostSellerListTile>;
      })}
    </div>
  );
}

function LeftFilterPage({ hook }) {
  return (
    <div className="w-0 invisible md:visible md:w-[20%] self-start text-start justify-start flex flex-col">
      {hook.topAttrbiutes.map((e) => (
        <AppExpandedWidget
          titleChild={<LeftListTile name={e?.name}></LeftListTile>}
          expandedChilds={hook.getAttrbiuteFromId(e?.id).map((e2) => {
            return (
              <div className="pl-2 pb-2">
                <AppCheckBox
                  value={e2?.name}
                  isSelected={hook.isSelected(e2.id)}
                  onChanged={(v) => {
                    hook.handleSelectedList(e2.id);
                  }}
                ></AppCheckBox>
              </div>
            );
          })}
        ></AppExpandedWidget>
      ))}
    </div>
  );
}

function LeftListTile({ name }) {
  return (
    <div className="text-sm md:text-base items-center flex flex-row w-full p-2">
      <div className="flex-1 p-1">{name} </div>
      <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
    </div>
  );
}
