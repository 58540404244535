import { useNavigate } from "react-router-dom";

export default function NavigateExtension() {
  const navigate = useNavigate();

  function goToPath(path) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(path);
  }
  return { goToPath };
}
