import React, { useEffect } from "react";

import AppLayoutHeader from "../../components/app_layout/header/AppLayoutHeader";
import AppSliders from "./widgets/AppSliders";
import MostSellers from "./widgets/MostSellers";

export default function HomeScreen() {
  return (
    <div className="flex flex-col ">
      <AppLayoutHeader></AppLayoutHeader>
      <AppSliders></AppSliders>
      <MostSellers></MostSellers>
    </div>
  );
}

export const HomeScreenPath = "/";
