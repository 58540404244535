import { createSlice } from "@reduxjs/toolkit";

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    listData: [],
    isLoading: false,
  },
  reducers: {
    setIsLoadingCategoriRedux: (state, action) => {
      state.isOpen = action.payload;
    },

    setListDataCategoriRedux: (state, action) => {
      state.listData = action.payload;
    },
  },
});

export const { setIsLoadingCategoriRedux, setListDataCategoriRedux } =
  categoriesSlice.actions;

export default categoriesSlice.reducer;
