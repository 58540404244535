import { useSelector } from "react-redux";
import { append, destory, destoryAll } from "../redux/modal_redux/ModalRedux";
import store from "../redux/redux_index";

export const useModals = () => useSelector((state) => state.modal.modals);

export const createModal = ({ name, title, data = false }) =>
  store.dispatch(append({ title, name, data }));

export const destoryModal = () => store.dispatch(destory());
export const destoryAllModal = () => store.dispatch(destoryAll());
