import { configureStore } from "@reduxjs/toolkit";
import BreadCrumbRedux from "./bread_crumb_redux/BreadCrumbRedux";
import CanvasRedux from "./canvas_redux/CanvasRedux";
import ModalRedux from "./modal_redux/ModalRedux";
import SnackbarRedux from "./snackbar_redux/SnackbarRedux";
import AuthRedux from "./auth_redux/AuthRedux";
import CategoriesRedux from "./categories_redux/CategoriesRedux";
import AttributesRedux from "./attributes_redux/AttributesRedux";

export default configureStore({
  reducer: {
    auth: AuthRedux,
    modal: ModalRedux,
    snackbar: SnackbarRedux,
    canvas: CanvasRedux,
    breadCrumb: BreadCrumbRedux,
    categories: CategoriesRedux,
    attributes: AttributesRedux,
  },
});
