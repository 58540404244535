import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBreadCrumbs from "../../components/AppBreadCrumbs";
import AppLayoutHeader from "../../components/app_layout/header/AppLayoutHeader";
import { nameFromPath } from "../../utils/dummy_data";
import { setBreadCrumbsList } from "../../utils/extensions/BreadCrumbsExtension";
import MostSellers from "../home_screen/widgets/MostSellers";
import { GET_PRODUCT } from "../../constant/end_point";
import NetworkService from "../../manager/network_manager";

export default function ProductDetailScreen() {
  const { id, subId } = useParams();

  const [product, setProduct] = useState(null);
  const { sendPost } = NetworkService();

  async function fetchProducts() {
    const response = await sendPost({
      endPoint: GET_PRODUCT,
      postData: { BreadcrumbName: subId },
    });

    if (response.success != true) {
      return {
        success: false,
        message: response.message,
      };
    }

    setProduct(response.data);

    setBreadCrumbsList({
      value: {
        id: subId,
        name: response.data?.name,
      },
    });
  }

  useEffect(() => {
    if (subId == null) return;

    fetchProducts();
  }, [subId]);

  return (
    <div className="flex flex-col">
      <AppLayoutHeader></AppLayoutHeader>
      <AppBreadCrumbs></AppBreadCrumbs>
      <BodyProduct product={product}></BodyProduct>

      <MostSellers></MostSellers>
    </div>
  );
}

function BodyProduct({ product }) {
  return (
    <div className="flex flex-col  md:flex-row pt-10">
      <BodyImage productImage={product?.imageUrl}></BodyImage>
      <BodyDescription product={product}></BodyDescription>
    </div>
  );
}

function BodyImage({ productImage }) {
  return (
    <div className="w-[100%] md:w-[50%] h-[60vh]">
      <div className="object-contain flex w-full h-full justify-center">
        <img className="" alt="" src={productImage}></img>
      </div>
    </div>
  );
}

function BodyDescription({ product }) {
  const { price } = product || {};
  return (
    <div className="w-[100%] md:w-[50%] flex flex-col">
      <div className="p-2 flex content-center justify-center md:justify-start text-2xl font-bold underline">
        {product?.name}
      </div>
      <div className="p-2 text-xl self-center md:self-start  font-semibold">
        {`${price?.amount} ${price?.currenySembol}`}
      </div>

      <div className="self-center md:self-start w-max bg-slate-600 text-white rounded-md hover:bg-slate-700 hover:cursor-pointer">
        <div
          onClick={() => {
            window.open(product?.goingToUrl);
          }}
          className=" pl-5 pr-5 pt-2 pb-2"
        >
          HEMEN AL
        </div>
      </div>

      <h1 className="pl-5 md:pl-2 pt-10 font-normal text-2xl">Açıklama</h1>

      <div className="p-5 md:p-1">
        <div dangerouslySetInnerHTML={{ __html: product?.description }} />
      </div>
    </div>
  );
}
