import React from "react";

import { useModals } from "../utils/extensions/modal";
import { modalList } from "../utils/modals/modal";
import AppModalTitle from "./AppModalTitle";

export default function AppModal({ closeModal }) {
  const modals = useModals();
  return (
    <>
      {modals.map((e1, i) => {
        const m = modalList.find((e2) => e1?.name === e2?.name);
        if (m !== undefined) {
          return (
            <div className="fixed inset-0 flex items-center justify-center z-50  p-8">
              <div className=" flex modal-bg fixed inset-0 bg-gray-800 opacity-75 items-center justify-center "></div>
              <div className="flex flex-col modal-content bg-white pb-8 pl-5 pr-5 rounded shadow-lg z-50  items-center justify-center animate-expand-center">
                <AppModalTitle title={e1.title}></AppModalTitle>
                <m.element
                  key={i}
                  probs={e1.data}
                  closeModal={closeModal}
                ></m.element>
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
