import { createSlice } from "@reduxjs/toolkit";

export const canvas = createSlice({
  name: "canvas",
  initialState: {
    isOpen: false,
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setIsOpen } = canvas.actions;

export default canvas.reducer;
