import React, { useMemo, useState } from "react";
import { useAttributesList } from "../../utils/extensions/AttributesExtension";
import NetworkService from "../../manager/network_manager";
import { GET_PRODUCT } from "../../constant/end_point";

export default function ProductScrenHook() {
  const { sendPost } = NetworkService();
  const allAttributes = useAttributesList();
  const [selectedList, setSelectedList] = useState([]);

  const [products, setProducts] = useState([]);

  const getFiltredProducts = useMemo(() => {
    if (selectedList.length == 0) return products;
    return products.filter((e) => {
      return e.attributes.some((e1) => selectedList.some((e2) => e1 == e2));
    });
  }, [selectedList, products]);

  async function fetchProducts({ id }) {
    const response = await sendPost({
      endPoint: GET_PRODUCT,
      postData: { kategoriId: id },
    });

    if (response.success != true) {
      return {
        success: false,
        message: response.message,
      };
    }

    setProducts(response.data);
  }

  const getFilterAttrbiutes = useMemo(() => {
    var distinctProductAttributes = products.flatMap((e) => e.attributes);

    distinctProductAttributes = new Set(distinctProductAttributes);

    distinctProductAttributes = [...distinctProductAttributes];

    const filterAttributes = allAttributes.filter((e1) => {
      return distinctProductAttributes?.some((e2) => e1.id == e2);
    });
    return filterAttributes;
  });

  const topAttrbiutes = allAttributes.filter((e) => e.topCategoriId == null);

  const getAttrbiuteFromId = (id) => {
    return getFilterAttrbiutes.filter((e) => e.topCategoriId == id);
  };

  const isSelected = (id) => selectedList.some((e) => e == id);

  const handleSelectedList = (id) => {
    if (isSelected(id)) {
      const filterList = selectedList.filter((e) => e != id);

      setSelectedList(filterList);
    } else {
      setSelectedList([...selectedList, id]);
    }
  };

  return {
    isSelected,
    handleSelectedList,
    getAttrbiuteFromId,
    topAttrbiutes,
    products,
    fetchProducts,
    getFiltredProducts,
  };
}
