import React, { useState } from "react";

function AppExpandedWidget({ titleChild, expandedChilds, initExpanded }) {
  const [isExpanded, setIsExpanded] = useState(initExpanded);
  return (
    <div className="flex flex-col h-full w-full">
      <div
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        {titleChild}
      </div>
      {isExpanded === true ? <div> {expandedChilds}</div> : null}
    </div>
  );
}

export default AppExpandedWidget;
