import { useSelector } from "react-redux";
import { addNew, addNewList } from "../redux/bread_crumb_redux/BreadCrumbRedux";
import store from "../redux/redux_index";
export const useBreadCrumbsList = () =>
  useSelector((state) => state.breadCrumb.breadCrumbs);

export const setBreadCrumbsList = ({ value }) => {
  store.dispatch(addNew(value));
};

export const setBreadCrumbsListFromList = ({ value }) => {
  store.dispatch(addNewList(value));
};
