import { useSelector } from "react-redux";

import store from "../redux/redux_index";
import {
  append,
  destory,
  destoryAll,
} from "../redux/snackbar_redux/SnackbarRedux";

export const useSnackbars = () =>
  useSelector((state) => state.snackbar.snackbars);

export const createSnackbar = ({ name, data = false }) => {
  store.dispatch(append({ name, data }));
  setTimeout(() => {
    destorySnackbar();
  }, 3000);
};

export const destorySnackbar = () => store.dispatch(destory());
export const destoryAllSnackbar = () => store.dispatch(destoryAll());
