import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AppModal from "../components/AppModal";
import AppSnackBar from "../components/AppSnackBar";
import HomeScreen, { HomeScreenPath } from "../screens/home_screen/HomeScreen";
import ProductDetailScreen from "../screens/products_screen/ProductDetailScreen";
import ProductsCategoriesScreen, {
  ProductScreenCategoriesPath,
} from "../screens/products_screen/ProductsCategoriesScreen";
import ProductsScreen from "../screens/products_screen/ProductsScreen";
import { useSnackbars } from "../utils/extensions/SnackbarExtension";
import { destoryModal, useModals } from "../utils/extensions/modal";
import {
  setCategoriesList,
  useCategoriesList,
} from "../utils/extensions/CategoriExtension";
import NetworkService from "../manager/network_manager";
import { GET_ATTRIBUTES, GET_KATEGORIES } from "../constant/end_point";
import { setBreadCrumbsListFromList } from "../utils/extensions/BreadCrumbsExtension";
import {
  setAttributesList,
  useAttributesList,
} from "../utils/extensions/AttributesExtension";

export default function AppRoutes() {
  const listCategories = useCategoriesList();

  const listAttributes = useAttributesList();
  const { sendGet } = NetworkService();
  useEffect(() => {
    if (listCategories.length == 0) {
      checkCategoriListAndFetch();
    }
    if (listAttributes.length == 0) {
      checkAttributeListAndFetch();
    }
  }, []);

  async function checkCategoriListAndFetch() {
    const response = await sendGet({ endPoint: GET_KATEGORIES });

    if (response.success != true) {
      return {
        success: false,
        message: response.message,
      };
    }

    setCategoriesList({ data: response.data });

    setBreadCrumbsListFromList({
      value: response.data.map((e) => {
        return {
          id: e.breadcrumbName,
          name: e.name,
        };
      }),
    });
  }
  async function checkAttributeListAndFetch() {
    const response = await sendGet({ endPoint: GET_ATTRIBUTES });

    if (response.success != true) {
      return {
        success: false,
        message: response.message,
      };
    }

    setAttributesList({ data: response.data });
  }

  const modals = useModals();
  const snackbars = useSnackbars();

  return (
    <>
      <BrowserRouter>
        {snackbars.length > 0 ? <AppSnackBar></AppSnackBar> : null}
        {modals.length > 0 ? (
          <AppModal closeModal={destoryModal}></AppModal>
        ) : null}
        <Routes>
          <Route path={HomeScreenPath} element={<HomeScreen />} />
          <Route
            path={ProductScreenCategoriesPath}
            element={<ProductsCategoriesScreen />}
          />
          <Route
            path={ProductScreenCategoriesPath + "/:id"}
            element={<ProductsScreen></ProductsScreen>}
          ></Route>
          <Route
            path={ProductScreenCategoriesPath + "/:id/:subId"}
            element={<ProductDetailScreen></ProductDetailScreen>}
          />
          <Route path="*" element={<p>Path not resolved</p>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
