import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function AppCarousel({
  sliders,
  autoSlide = false,
  autoSlideInterval = 3000,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? sliders.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === sliders.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="relative w-full md:h-[70vh]  h-56">
      <img
        src={sliders[currentSlide]}
        alt={`Slide ${currentSlide + 1}`}
        className="w-full h-full object-cover"
      />
      <div
        className="text-sm hover:cursor-pointer absolute top-1/2 left-2 transform -translate-y-1/2 px-3 py-2 bg-gray-800 text-white rounded-full"
        onClick={prevSlide}
      >
        <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
      </div>
      <div
        className="text-sm hover:cursor-pointer absolute top-1/2 right-2 transform -translate-y-1/2 px-3 py-2 bg-gray-800 text-white rounded-full"
        onClick={nextSlide}
      >
        <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
      </div>
    </div>
  );
}
