import { useSelector } from "react-redux";
import store from "../redux/redux_index";
import {
  setIsLoadingCategoriRedux,
  setListDataCategoriRedux,
} from "../redux/categories_redux/CategoriesRedux";
export const useCategoriesList = () =>
  useSelector((state) => state.categories.listData);

export const useCategoriFromId = (id) => {
  const list = useSelector((state) => state.categories.listData);

  return list.find((e) => e.id == id);
};

export const useCategoriesIsLoading = () =>
  useSelector((state) => state.categories.isLoading);

export const setCategoriesLoading = ({ isLoading }) => {
  store.dispatch(setIsLoadingCategoriRedux(isLoading));
};

export const setCategoriesList = ({ data }) => {
  store.dispatch(setListDataCategoriRedux(data));
};
