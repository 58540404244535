import { createSlice } from "@reduxjs/toolkit";

export const breadCrumb = createSlice({
  name: "breadCrumb",
  initialState: {
    breadCrumbs: [],
  },
  reducers: {
    addNew: (state, action) => {
      const newList = [...state.breadCrumbs, action.payload];
      const newUniqSet = new Set(newList);
      state.breadCrumbs = [...newUniqSet];
    },

    addNewList: (state, actions) => {
      const newList = [...state.breadCrumbs, ...actions.payload];
      const newUniqSet = new Set(newList);
      state.breadCrumbs = [...newUniqSet];
    },
  },
});

export const { addNew, addNewList } = breadCrumb.actions;

export default breadCrumb.reducer;
