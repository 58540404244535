import { createSlice } from "@reduxjs/toolkit";

export const attributesSlice = createSlice({
  name: "attributes",
  initialState: {
    listData: [],
    isLoading: false,
  },
  reducers: {
    setIsLoadingAttributeRedux: (state, action) => {
      state.isOpen = action.payload;
    },

    setListDataAttributeRedux: (state, action) => {
      state.listData = action.payload;
    },
  },
});

export const { setIsLoadingAttributeRedux, setListDataAttributeRedux } =
  attributesSlice.actions;

export default attributesSlice.reducer;
